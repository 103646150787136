import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/business/requestForLeave/pageList", formData);
}

function remove(id) {
  return request.post(constant.serverUrl + "/business/requestForLeave/delete/" + id);
}

export default {pageList,remove}